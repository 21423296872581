import instance from './request'

//获取父级文件夹
export function getFileDatas(dataObj) {
  return instance({
    url: 'esop/getTreeList',
    method: 'POST',
    params: dataObj
  })
}

//搜索文件
export function getProcessFileByFileName(dataObj) {
  return instance({
    url: 'esop/fuzzyNameList',
    method: 'POST',
    params: dataObj
  })
}

//获取文件夹里所有的文件
export function getProcessFileTableData(dataObj) {
  return instance({
    url: 'esop/paperData',
    method: 'POST',
    params: dataObj
  })
}

//根据卡号切换指导书
export function changeFileByScan(dataObj) {
  return instance({
    url: 'esop/scanFile',
    method: 'POST',
    params: dataObj
  })
}