<template>
	<div class="animation-video">
		
		<div class="container">
			<div class="title">
				<span class="icon1">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<div class="fullScreen">
				<el-button type="success" size="medium" icon="el-icon-full-screen" circle @click="isFullScreen"></el-button>
			</div>
			<div class="carousel-container" v-if="pics && pics.length == 1">
					<div class="img-container" v-for="(item,index) in pics" :key="index" style="width: 100%; height: 100%;">
						<img v-lazy="item.fileUrl" height="100%" preview="index"/>
					</div>
				</div>
			<div class="carousel-container" v-if="pics && pics.length > 1">
				<swiper :options="swiperOption" ref="mySwiper" style="width: 100%; height: calc(100% - 40px)">
					<swiper-slide class="swiper-slide" v-for="(item,index) in pics" :key="index" style="width: 100%; height: 100%;">
					    <img :src="item.fileUrl" v-if=" index == 0 || index == (pics.length - 1)" preview="0" />
					    <img v-lazy="item.fileUrl" v-else preview="0" />
					</swiper-slide>
				</swiper>
			</div>
			 <div v-else class="remain-txt">
			 	暂 无 数 据
			 </div>
		</div>
		<div class="expand-container">
			<div class="title">
				<span class="icon2">
					<i class="iconfont icon-richscan_icon"></i>
				</span>
				<span class="txt">扫码/刷卡</span>
			</div>
			<el-form label-position="left" label-width="60px" @submit.native.prevent style="padding: 0.625rem;">
				<el-form-item label="文本" class="remain-form-box">
					<el-input v-model="cardNoInput" ref="inputs" @keyup.enter.native="getCardNo($event)" clearable></el-input>
				</el-form-item>
				<el-form-item label="提示" class="remain-form-box">
					<el-input v-if="isRight" class="remain-form-item1" type="textarea" readonly v-model="remainInput" rows="10" clearable></el-input>
					<el-input v-else class="remain-form-item2" type="textarea" readonly v-model="remainInput" rows="10" clearable></el-input>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog
		  title="检验报告"
		  :visible.sync="dialogVisible"
		  width="80%"
		  center>
			<div>
				<!-- <div class="submitByScan">
					<el-input v-model="submitCardNo" placeholder="请确认刷卡提交" ref="inputs" @keyup.enter.native="submitByScan($event)" clearable></el-input>
				</div> -->
				<!-- <div class="product-info">
					<div>
						<div><span class="info-title">产品SN：</span><span>{{productionSn ? productionSn : "--"}}</span></div>
						<div><span class="info-title">产品编码：</span><span>{{productCode ? productCode : "--"}}</span></div>
						<div><span class="info-title">产品名称：</span><span>{{productName ? productName : "--"}}</span></div>
					</div>
					<div>
						<div><span class="info-title">装配总时长：</span><span>{{assemblyTime ? assemblyTime : "--"}}</span></div>
						<div><span class="info-title">装配人员：</span><span>{{productPerson ? productPerson : "--"}}</span></div>
						<div><span class="info-title">是否合格：</span><el-switch v-model="result" :active-value="1" :inactive-value="0"></el-switch></div>
					</div> 
				</div> -->
				
				<el-form label-position="left" label-width="120px" :model="productInfo">
					<el-row :gutter="20">
						<el-col :span="8">
							<el-form-item label="刷卡提交" class="form-item-box">
								<el-input v-model="submitCardNo" placeholder="请确认刷卡提交" ref="inputs" @keyup.enter.native="submitByScan($event)" clearable></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						 <el-col :span="6">
							<el-form-item label="产品SN" class="form-item-box">
								<el-input v-model="productInfo.productionSn" readonly></el-input>
							</el-form-item>
						</el-col>
						<!--
						<el-col :span="6">
							<el-form-item label="产品编码" class="form-item-box">
								<el-input v-model="productInfo.productionCode" readonly></el-input>
							</el-form-item>
						</el-col>
						-->
						<el-col :span="6"> 
							<el-form-item label="产品名称" class="form-item-box">
								<el-input v-model="productInfo.productName" readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="装配总时长" class="form-item-box">
								<el-input v-model="productInfo.assemblyTime" readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="是否合格" class="form-item-box">
								<el-switch v-model="productInfo.result" :active-value="1" :inactive-value="0"></el-switch>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<!-- <el-col :span="6">
							<el-form-item label="开始时间" class="form-item-box">
								<el-input v-model="productInfo.beginTime" readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="结束时间" class="form-item-box">
								<el-input v-model="productInfo.endTime" readonly></el-input>
							</el-form-item>
						</el-col> -->
						
						<el-col :span="6">
							<el-form-item label="装配人员" class="form-item-box">
								<el-input v-model="productInfo.productPerson" readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="备注" class="form-item-box">
								<el-input v-model="productInfo.cause"></el-input>
							</el-form-item>
						</el-col>
						
					</el-row>
					
					
				</el-form>
				<el-table
					class="table1"
					ref="table"
					:data="tableData"
					height="250" 
					:row-class-name="tableRowClassName"
					:height="tableHeight"
					:header-row-style="{height: '50px'}"
					:row-style="{height: '50px'}"
					style="width: 100%;">
					<el-table-column type="index" width="60"  align="center"></el-table-column>
					<el-table-column label="零配件名称" prop="materialName" min-width="14%" align="center"></el-table-column>
					<el-table-column label="零配件型号" prop="materialModel" min-width="14%" align="center"></el-table-column>
					<el-table-column label="图片" prop="materialUrl" min-width="10%" align="center">
						<template slot-scope="scope">
							<img :src="scope.row.materialUrl" alt="" width="50">				
						</template>
					</el-table-column>
					<el-table-column label="开始时间" prop="beginTime" min-width="15%" align="center"></el-table-column>
					<el-table-column label="结束时间" prop="endTime" min-width="15%" align="center"></el-table-column>
					<el-table-column label="装配时间" prop="assemblyTime" min-width="15%" align="center"></el-table-column>
					<el-table-column label="装配人" prop="userName" min-width="15%" align="center"></el-table-column>
					<el-table-column label="是否合格" min-width="12%" align='center'>
						<template slot-scope="scope">
							<el-switch
								v-model="scope.row.result"
								:active-value="1"
								:inactive-value="0"
							>
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column label="备注" prop="cause" min-width="15%" align="center">
						<template slot-scope="scope">
							<el-input class="remain-form-item" type="textarea" v-model="scope.row.cause" rows="2" clearable></el-input>	
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getSopFile,
		getProjectByScan,
		addInspectionReport
	} from 'network/newProject'
	import {
		changeFileByScan
	} from 'network/processFile.js'
	export default {
		name: "animationVideo",
		data() {
			return {
				id: sessionStorage.getItem("menuId"),
				title: sessionStorage.getItem("menuName"),
				timer: "",
				tableData: '',
				productionSn: sessionStorage.getItem("productionSn"),//产品SN
				outNums: sessionStorage.getItem("outNums"),//产品完成数量
				productCode: sessionStorage.getItem("productCode"),//产品编码
				workOrderNo: sessionStorage.getItem("workOrderNo"),//工单号
				planCount: sessionStorage.getItem("planCount"),//计划数量
				productInfo: {
					productId: "",
					result: "",
					productName: "",
					productionSn: "",
					productCode: "",
					productPerson: "",
					beginTime: "",
					endTime: "",
					resultType: "",
					assemblyTime: "",
					cause: ""
				},
				// 返回信息是否正确
				isRight: true,
				tableHeight: 500,
				searchInput: '',
				cardNoInput: '',
				remainInput: '',
				submitCardNo: "",
				inspect: "",
				baseUrl: sessionStorage.getItem("baseUrl"),
				dialogVisible: false,
				swiperOption: {
					initialSlide:0,//设定初始化时slide的索引
					direction:'horizontal',//Slides的滑动方向，可设置水平(horizontal)或垂直(vertical)。
					loop: false,  //无限滚动
					speed:300,//滑动速度
					autoplay: {
					  delay: 5000,
					  disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
					  stopOnLastSlide: false, // 在最后一页停止
					},
					zoom: true,
				},
			}
		},
		computed: {
		    swiper() {
		      return this.$refs.mySwiper.swiper;
		    }
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			},
			pics: {
				type: Array,
				default: ""
			}
		},
		beforeDestroy() {
			if(this.timer) {
				clearInterval(this.timer); //关闭
			}  //利用vue的生命周期函数
		},
		created() {},
		methods: {
			isFullScreen() {
				this.$bus.$emit('fullScreenChange');
			},
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			 picsChange(arr) {
				this.$bus.$emit('picsChange', arr);
			},
			changeFile(code) {
				// console.log(this.fileName);
				let obj = {
					code: code,
					eqId: sessionStorage.getItem("eqId"),
					deptId: sessionStorage.getItem("stationId"),
					userId: sessionStorage.getItem("userId")
				}
				// console.log(obj.code + "------" + obj.eqId + "-----" + obj.deptId + "-----" + obj.userId);
				changeFileByScan(obj).then(res => {
					
					this.isRight = true;
					if(res.data.code != 0) {
						this.isRight = false;
						this.remainInput =  res.data.msg;
					}
					this.remainInput =  res.data.msg;
					this.cardNoInput = "";
				})
			},
			//输入卡号时自动聚焦
			changeFocus(){
				this.$nextTick(function () {
					this.$refs.inputs.$el.querySelector('input').focus();
				});
			},
			getCardNo(e) {
				// console.log(e.target.value);
				if(e.target.value == "" || e.target.value == null) {
					return
				} 
				this.changeFile(e.target.value);
			},
			// getCardNo (e) {
			// 	this.remainInput = "";
			// 	let obj = {
			// 		card: e.target.value,
			// 		inspect: this.inspect,
			// 		eqId: sessionStorage.getItem("eqId"),
			// 		productionSn: sessionStorage.getItem("productionSn"),
			// 		stationId: sessionStorage.getItem("stationId"),
			// 		operatorId: sessionStorage.getItem("userId")
			// 	}
			// 	getProjectByScan(obj).then(res => {
			// 		var isRight = true;
			// 		if(res.data.code == 0) {
			// 			var isRight = true;
			// 			var resData = res.data.data;
			// 			var fileData = [];
			// 			var tableData = [];
			// 			var i = 0;
			// 			var j = 0;
			// 			var k = 0;
			// 			//检验轮播图数据是否存在
			// 			var fileFlag = false;
			// 			//检验报告数据是否存在
			// 			var flag = false;
			// 			resData.forEach((item, index) => {
			// 				if(item.filePath) {
			// 					// console.log(1234567899);
			// 					fileFlag = true;
			// 					fileData.push(this.baseUrl + item.filePath);
			// 				}
			// 				//接收检验报告的数据
			// 				//产品信息
			// 				if(item.type == 1) {
			// 					console.log(item.resultType);
			// 					k = index;
			// 					this.productInfo.productName = item.productName;
			// 					this.productInfo.productionSn = item.productSn;
			// 					this.productInfo.productCode = item.ProductCode;
			// 					this.productInfo.productId = item.id;
			// 					this.productInfo.result = item.result;
			// 					this.productInfo.reusltType = item.resultType;
			// 					this.productInfo.productPerson = item.userName;
			// 					this.productInfo.beginTime = item.beginTime;
			// 					this.productInfo.endTime = item.endTime;
			// 					this.productInfo.assemblyTime = item.assemblyTime;
			// 					this.productInfo.cause = item.cause;
			// 					tableData.push(item);
			// 				}
			// 				//接收表格的数据
			// 				if(item.type == 2) {
			// 					item.materialUrl = this.baseUrl + item.materialUrl;
			// 					tableData.push(item);
			// 					flag = true;
			// 				}
			// 				//接收验证指令
			// 				if(item.mark == 0 ){
			// 					this.inspect = item.inspect;
			// 				//接收录入产品SN的产品信息
			// 				}else if(item.mark == 1) {
			// 					sessionStorage.setItem("planCount", item.planCount)
			// 					sessionStorage.setItem("productionSn", item.productionSn)
			// 					sessionStorage.setItem("productCode", item.productCode)
			// 					sessionStorage.setItem("workOrderNo", item.workOrderNo)
			// 					sessionStorage.setItem("outNums", item.outNums)
			// 					this.planCount = item.planCount;
			// 					this.productionSn = item.productionSn;
			// 					this.productCode = item.productCode;
			// 					this.workOrderNo = item.workOrderNo;
			// 					this.outNums = item.outNums;
			// 					i = index;
			// 				}else if(item.mark == 2) {
			// 					this.remainInput = item.reminder;
			// 				}else if(item.mark == 4) {
			// 					this.remainInput = item.reminder;
			// 					j = index;
			// 				}
			// 				this.remainInput = item.reminder;
			// 			})
			// 			if(fileFlag) {
			// 				sessionStorage.removeItem("pics");
			// 				sessionStorage.setItem("pics", JSON.stringify(fileData));
			// 			}
			// 			// console.log(this.tableData[k]);
			// 			tableData.splice(j, 1);
			// 			tableData.splice(k, 1);
			// 			this.tableData = tableData;
			// 			if(flag) {
			// 				this.dialogVisible = true;
			// 			}
			// 		}else {
			// 			this.isRight = false;
			// 			return this.remainInput = res.data.msg;
			// 		}
			// 		this.cardNoInput = "";
			// 		this.changeFocus();
			// 	})
			// },
			// submitByScan(e) {
			// 	let obj = {
			// 		card: e.target.value,
			// 		eqId: sessionStorage.getItem("eqId"),
			// 		productionSn: sessionStorage.getItem("productionSn")
			// 	}
			// 	var submitData = [];
			// 	submitData = this.tableData;
			// 	var arr = {
			// 		id: "",
			// 		result: "",
			// 		cause: ""
			// 	}
			// 	arr.id = this.productInfo.productId;
			// 	arr.result = this.productInfo.result;
			// 	arr.cause = this.productInfo.cause;
			// 	submitData.push(arr);
			// 	submitData.push(obj);
			// 	// this.tableData.push(obj);
			// 	addInspectionReport(submitData).then(res => {
			// 		if(res.data.code == 0) {
			// 			sessionStorage.removeItem("pics");
			// 			this.remainInput = res.data.data[0].reminder;
			// 			this.submitCardNo = "";
			// 			this.dialogVisible = false
			// 		}else {
			// 			return this.$message.error(res.data.msg);
			// 		}
			// 	})
			// 	arr = [];
			// 	// console.log(arr);
			// },
			//输入卡号时自动聚焦
			changeFocus(){
			    this.$nextTick(() => {
			       this.$refs.inputs.focus();
			    })
			},
		}
	}
</script>
<style>
	.el-form-item__label {
		font-size: 1.125rem !important;
		color: #000000 !important;
	}
	.el-input__inner {
		font-size: 1.125rem !important;
		border: 2px solid #dcdfe6 !important;
	}
	.el-textarea__inner {
		border: 2px solid #dcdfe6 !important;
	}
	.remain-form-box .remain-form-item1 .el-textarea__inner {
		font-size: 1.125rem !important;
	    color: green !important;
	}
	.remain-form-box .remain-form-item2 .el-textarea__inner {
		font-size: 1.125rem !important;
	    color: red !important;
	}
</style>
<style lang="scss" scoped>
@media all and (orientation : landscape) {
	// .close i { color: red;}/*横屏时字体红色*/
	.swiper-slide img {
		height: 100%;
	}
} 
@media all and (orientation : portrait){ 
	.swiper-slide img {
		width: 100%;
		height: 100%;
	}
}
$mainBgc: #F4F5FA;
.animation-video {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	background-color: $mainBgc;
	border-radius: 0.625rem;
	.title {
		width: 100%;
		padding: 0.625rem;
		display: flex;
		.icon1, .icon2 {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.icon2 {
			background: linear-gradient(0deg,rgba(255,125,29,1) 0%,rgba(255,189,32,1) 100%);
		}
		.txt {
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			letter-spacing: 3px;
			line-height: 2.5rem;
		}
	}
	.fullScreen {
		position: absolute;
		top: 0.625rem;
		right: 0.625rem;
	}
	
	.container {
		width: 74.5%;
		height: 100%;
		background-color: white;
		border-radius: 0.625rem;
		position: relative;
		box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
		.carousel-container {
			width: 100%;
			height: calc(100% - 20px);
			border-radius: 0.625rem;
			text-align: center;
		}
		.remain-txt {
			width: 100%;
			height: calc(100% - 80px);
			border-radius: 0.625rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.375rem;
			color: #ggg;
			letter-spacing: 4px;
		}
	}
	.el-dialog {
		position: relative;
		.product-info {
			width: 100%;
			padding: 0 0.625rem;
			display: flex;
			justify-content: space-between;
			div {
				font-size: 1.125rem;
				font-weight: 500;
				margin-top: 1.25rem;
				font-family: PingFang SC;
			}
			.info-title {
				font-size: 1.25rem;
				letter-spacing: 2px;
			}
		}
		.el-table__header tr,
		  .el-table__header th {
		    padding: 0 !important;
		    height: 3.75rem !important;
		}
		.el-table__body tr,
		  .el-table__body td {
		    padding: 0 !important;
		    height: 3.75rem !important;
		}
	}
	.expand-container {
		width: 25.5%;
		height: 100%;
		background: white;
		border-radius: 0.625rem;
		box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
		margin-left: 1.25rem;
		.product-info {
			width: 100%;
			padding: 0 0.625rem;
			div {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				font-size: 1.125rem;
				font-weight: 500;
				margin-bottom: 0.625rem;
				font-family: PingFang SC;
			}
		}
	}
	
}	
</style>


 